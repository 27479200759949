import "./styles.scss";
import React, {useEffect, useState} from "react";
import Topbar from "../../components/sections/DetailsTopBar/DetailsTopBar";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import MapCardSlider from "../../components/sections/MapCardSlider/MapCardSlider";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {getAppConfig} from "../../redux/slices/appconfigSlice";
import LocationGray from '../../assets/images/locationGray.svg';
import LocationGreen from '../../assets/images/LocationGreen.svg';
import AppConfig from "../../helpers/config";

const MapPage = () => {
    const { hotelId } = useParams(); // Get the numeric ID from the URL
    const appConfig = useSelector(getAppConfig);

    const [selectedHotel, setSelectedHotel] = useState(null);
    const [hotelLocations, setLocations] = useState([]);

    const defaultProps = {
        center: { lat: 10.99835602, lng: 77.01502627 }, // Default center (fallback)
        zoom: 10,
    };

    useEffect(() => {
        window.scroll(0, 0);

        const selected = appConfig.offers?.find(f => f.id === hotelId);
        const locations = appConfig.offers?.map(f => {
            const geoLocation = f?.location?.geoLocation;
            return {
                id: f.id,
                lat: geoLocation?.latitude,
                lng: geoLocation?.longitude,
                name: f?.name,
                selected: f.id === hotelId,
            };
        });

        setSelectedHotel(selected);
        setLocations(locations);

        console.log('Selected hotel:', {selectedHotel});
        console.log('Hotel locations:', locations);
    }, [hotelId, appConfig.offers]);

    return (
        <div className="main-wrapper flight-page">
            <main className="body-wrapper">
                <div style={{ height: "100vh", width: "100%" }}>
                    <div className="top-bar-container">
                        <Topbar isMap={true} />
                    </div>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: AppConfig.googleMapAPIKey}}
                        center={
                            selectedHotel?.location?.geoLocation
                                ? { lat: selectedHotel.location.geoLocation.latitude, lng: selectedHotel.location.geoLocation.longitude }
                                : defaultProps.center
                        }
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                    >
                        {hotelLocations?.map((location, index) => (
                            <Marker
                                key={index}
                                id={location.id}
                                lat={location.lat}
                                lng={location.lng}
                                text={location.name}
                                selected={location.selected}
                            />
                        ))}
                    </GoogleMapReact>
                    <MapCardSlider />
                </div>
            </main>
        </div>
    );
};

const Marker = (props) => {
    return (
        <div>
            {props.selected ? (
                <img src={LocationGreen} alt="marker-green-icon" />
            ) : (
                <img src={LocationGray} alt="marker-gray-icon" />
            )}
        </div>
    );
};

Marker.propTypes = {
    id: PropTypes.string,
    lat: PropTypes.string,
    lng: PropTypes.string,
    text: PropTypes.string,
    selected: PropTypes.bool
};

export default MapPage;
